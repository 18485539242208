import { useState, useEffect, Fragment, useContext } from 'react';
import './math.styles.scss';
import FlashCard from '../../1-components/13-flash-card/flash-card.component';
import Menue from './menu/menu.component';
import { Link } from 'react-router-dom';
import { MathContext } from '../../3-context/7-math.context';

const MathComponent = () => {
    const {setFiliere, setClasse, setTheme, setChapitre, setChapitreSelected, setFace, filiere} = useContext(MathContext)
    const [cards, setCards] = useState([]);
    const [currentCard, setCurrentCard] = useState(null);
    const [nextCard, setNextCard] = useState(null);
    const [currentCardPos, setCurrentCardPos] = useState('');
    const [nextCardPos, setNextCardPos] = useState('hide');
    const [current, setCurrent] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0);


    // useEffect hook to update the currentCard when cards are updated
    useEffect(() => {
        if (cards.length > 0) {
            setCurrentCard(cards[0]);  // Set the first card as the current card
            setNextCard(cards[0])
        }
       
    }, [cards]);  // This effect runs every time the `cards` state changes

    const handleNext = (direction) => {
      setFace('recto')
      let nextIndex = direction === 'right' ? currentIndex + 1 : currentIndex - 1;
      nextIndex = nextIndex === -1 ? cards.length - 1 : nextIndex;
      nextIndex = nextIndex === cards.length ? 0 : nextIndex;
      setCurrentIndex(nextIndex);
      
      if (current) {   
        setNextCard(cards[nextIndex])
        setCurrentCardPos(direction === 'left' ? 'right' : 'left')
        setNextCardPos(direction === 'left' ? 'centre-left' : 'centre-right')
        setCurrent(null)
        setCurrentCard(null)
      } else { 
        setCurrentCard(cards[nextIndex])
        setNextCardPos(direction === 'left' ? 'right' : 'left')

        setCurrentCardPos(direction === 'left' ? 'centre-left' : 'centre-right')
        setCurrent('oui')
        setNextCard(null)
      }
    }
    const handleBack = () => {
      setCards([])
      setFiliere(null)
      setClasse([])
      setTheme([])
      setChapitre([])
      setChapitreSelected([])
      setCurrentIndex(0)
      setCurrentCard(null);
      setNextCard(null);
      setCurrentCardPos('');
      setNextCardPos('hide');
      setCurrent(null);
      setFace('recto')
    }
    useEffect(() => {
      handleBack()
      const renderMath = async () => {
        if (window.MathJax && window.MathJax.typesetPromise) {
          try {
            await window.MathJax.typesetPromise();
          } catch (error) {
            console.error("Erreur pendant le rendu de MathJax :", error);
          }
        }
      };
      renderMath();
    }, [])

  return (
    <div className="math-container">
      {
        cards.length !== 0 ? (
          <Fragment>
            
            <div className="back" onClick={handleBack}><i className="fa-solid fa-arrow-left"></i></div>
            <div className='index'>{currentIndex + 1} / {cards.length}</div>
            <div className='left-btn' onClick={() => handleNext('left')}><i className="fa-solid fa-chevron-left"></i></div>
            {currentCard && <FlashCard cardInfo={currentCard} position={currentCardPos}/>}
            {nextCard && <FlashCard cardInfo={nextCard} position={nextCardPos} isNext="true"/>}
            <div className='right-btn' onClick={() => handleNext('right')}><i className="fa-solid fa-chevron-right"></i></div>
          </Fragment>
        ) : (
          <Fragment>
            <Link className='back' to={'../analyse'} onClick={handleBack}>
                <i className="fa-solid fa-arrow-left"></i>
            </Link>
            <Menue setCards={setCards}/>
          </Fragment>
        )
      }
    </div>
  );
};

export default MathComponent;
