import { Link, Navigate, useNavigate } from 'react-router-dom'
import './nav.mobile.scss'
import { googleSignOut, useAuthListener } from '../../4-utils/firebase.utils'
import { useState } from 'react'



const NavM = () => {
    const navigate = useNavigate()
    const handleDisconnect = () => {
        googleSignOut() 
        navigate('../..')
      }
      const [user, setUser] = useState(null)
      useAuthListener(setUser)
      console.log(!user);
      
    return (
            <nav className='desktopNav navMobile'>
                <Link to={'/'} className='logo'>MonColleur</Link>
                <ul className='nav-items'>
                    <li className='item'>
                        {user ? ( 
                            <button className="btn" onClick={handleDisconnect}>Se déconnecter</button>
                        ) : (
                            <Link to={"/authentication"} className="btn">Se connecter</Link>
                        )}
                    </li>
                </ul>
            </nav>        
    )
}
export default NavM