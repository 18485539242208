import { useContext, useEffect, useState } from 'react';
import './menu.styles.scss';
import dataChapter from '../data/chapter.json'
import dataAppro from '../data/CoursMathsAppro.json'
import dataAppli from '../data/CoursMathsAppli.json'
import dataEct from '../data/CoursMathsECT.json'
import { MathContext } from '../../../3-context/7-math.context';
import { PaymentContext } from '../../../3-context/2-payment.context';
import NotSub from '../../../1-components/10-not-sub/not-sub.component';
import { RequestContext } from '../../../3-context/4-request.context';

const Menue = ({setCards}) => {
  const { setSource} = useContext(RequestContext)
  const {isSub} = useContext(PaymentContext)
  const {filiere, setFiliere, classe, setClasse, theme, setTheme, setChapitre, chapitre, setChapitreSelected, chapitreSelected } = useContext(MathContext)
    const [availableFlashCard, setAvailableFlashCard] = useState([])
    const [value, setValue] = useState(0)
    const [max, setMax] = useState(0)
    const [isNotSub, setIsNotSub] = useState(false)
    const handleSelected = (e, choix) => {
      if (choix === 'filiere') {
        if (e.target.classList.contains('selected')) {
          setFiliere(null)
          e.target.classList.remove('selected')      
        }
        else 
        {
          setFiliere(e.target.innerText)
          let currentChapitreSelected = chapitreSelected 
          console.log(currentChapitreSelected);
          
          handleSelectedChapitre(true)
          handleSelectedChapitre(true)
          setChapitreSelected([])
          let filieres = document.getElementsByClassName('filiere-item')
          for (let i = 0; i < filieres.length; i++) {
            filieres[i].classList.remove('selected')
          }
          e.target.classList.add('selected')      
        }
      }
      else if (choix === 'classe') {
        const value = e.target.innerText === 'EC1' ? '1' : '2';
        
        if (e.target.classList.contains('selected')) {
          setClasse(prevClasse => {
            const nouveauClasse = prevClasse.filter(item => item !== value);
            return nouveauClasse;
          });          
          e.target.classList.remove('selected');
        } else {
          setClasse(prevClasse => {
            const nouveauClasse = [...prevClasse, value];
            return nouveauClasse; 
          });
          e.target.classList.add('selected');
        }
      }
      else 
      {
        if (e.target.classList.contains('selected')) {      
          setTheme(prevTheme => {
            const nouveauTheme = prevTheme.filter(item => item !== e.target.innerText)
            return nouveauTheme;
          })    
          e.target.classList.remove('selected')
        } else {
          setTheme(prevTheme => {
            const nouveauTheme = [...prevTheme, e.target.innerText];
            return nouveauTheme; 
          });
          e.target.classList.add('selected');
        }        
      }
    }


    useEffect(() => {      
      generateChapter()
      if(!filiere || classe.length === 0 || theme.length === 0) {
        setChapitreSelected([])
      }
    }, [filiere, classe, theme])

    useEffect(() => {      
      generateAvailableFlashCard()
      setValue(0)
    }, [chapitreSelected])

    const generateChapter = () => {
        let listeChapitre = []
        const chapterLength = Object.keys(dataChapter).length;
        
        for (let i = 0; i < chapterLength; i++) {          
          if (dataChapter[i].filiere === filiere && classe.includes(dataChapter[i].classe) && theme.includes(dataChapter[i].theme)) {
          listeChapitre.push(dataChapter[i]);
          }
        }
        if(theme.length === 0 || classe.length === 0) {                    
          setChapitre(() => [])
        }
        else {          
          setChapitre(() => listeChapitre)
        }
    }

    const handleSelectedChapitre = (all, e) => {
      if(all) 
      {
        let chapitres = document.getElementsByClassName('chapter');
        let chapitreNom = []
        let selectionner = false
        for (let i = 0; i < chapitres.length; i++) {
          if(!chapitres[i].classList.contains('selected-chapter')){
            selectionner = true
            break
          }
        }
        for (let i = 0; i < chapitres.length; i++) {
          selectionner ? chapitres[i].classList.add('selected-chapter') : chapitres[i].classList.remove('selected-chapter')
          chapitreNom.push(chapitres[i].innerText)
        }
        
        
        selectionner ? setChapitreSelected(chapitreNom) : setChapitreSelected([])
      }
      else {
        if (e.target.classList.contains('selected-chapter')) {
          e.target.classList.remove('selected-chapter')
          setChapitreSelected(prevChapitre => {
            const nouveauChapitre = prevChapitre.filter(item => item !== e.target.innerText)
            return nouveauChapitre;
          })  
        } else {
          setChapitreSelected(prevChapitre => {
            const nouveauChapitre = [...prevChapitre, e.target.innerText]
            return nouveauChapitre;
          })  
          e.target.classList.add('selected-chapter') 
        }
      }
    }
    const generateAvailableFlashCard = () => {
      let dataMath = filiere === 'Appro' ? dataAppro : filiere === 'Appli' ? dataAppli : dataEct;
      let availableCards = []
      const cardLength = Object.keys(dataMath).length;
      
      for (let i = 0; i < cardLength; i++) {
        if (chapitreSelected.includes(dataMath[i].chapitre)) {
          availableCards.push(dataMath[i])
        }
      }

      // Set the cards state with the newly selected cards
      setAvailableFlashCard(availableCards)
      setMax(availableCards.length)
    }
    const generateFlashCard = () => {
      let chosenFlashCards = [] 
      let maxIndex = availableFlashCard.length
      let randomIndex = Math.floor(Math.random() * maxIndex)
      for (let i = 0; i < value; i++) 
      {
        while (chosenFlashCards.includes(availableFlashCard[randomIndex]))
        {
          randomIndex = Math.floor(Math.random() * maxIndex)
        }
        chosenFlashCards.push(availableFlashCard[randomIndex])
        randomIndex = Math.floor(Math.random() * maxIndex)
      }
      setCards(chosenFlashCards)
    }
    const handleChange = (e) => {
      setValue(Number(e.target.value));
    };

    const handleGo = () => {
      if(!isSub)
      {
        setSource('math')
        setIsNotSub(true)
      }
      else 
      {
        setIsNotSub(false)
        generateFlashCard()
      }
    }
    return (
        <div className='menu-page'>
        <div className='menu-title'>Prêt à réviser les <span className='blue'>Maths</span> ?</div>
        <div className='menu-container'>
          <div className={`menu ${chapitre.length === 0 ? 'right' : 'left'}`}>
            <div className='menu-subtitle'>Choisis <span className='red'>ta séance</span> de révision </div>
            <ul>
              <li className='filiere-item' onClick={(e) => handleSelected(e, 'filiere')}>Appro</li>
              <li className='filiere-item' onClick={(e) => handleSelected(e, 'filiere')}>Appli</li>
              <li className='filiere-item' onClick={(e) => handleSelected(e, 'filiere')}>ECT</li>
            </ul>
            <ul>
              <li className='classe-item' onClick={(e) => handleSelected(e, 'classe')}>EC1</li>
              <li className='classe-item' onClick={(e) => handleSelected(e, 'classe')}>EC2</li>
            </ul>
            <ul>
              <li className='theme-item' onClick={(e) => handleSelected(e, 'theme')}>Analyse</li>
              <li className='theme-item' onClick={(e) => handleSelected(e, 'theme')}>Probas</li>
              <li className='theme-item' onClick={(e) => handleSelected(e, 'theme')}>Algèbre</li>
            </ul>
          </div>
          <div className='chapter-menu'>
            {
              chapitre.map((item) => (
                <div key={item.name} onClick={(e) => handleSelectedChapitre(false, e)} className='chapter'>{item.name}</div>
              ))
            }
            {
              chapitre.length > 0 && (
                <div className='chapter-all' onClick={() => handleSelectedChapitre(true)}>Tout séléctioner</div>
              )
            }
          </div>

        </div>
        {max > 0 && (
        <div className="slider-gauge">
            <div className="slider-wrapper">
              <div
                className="slider-value"
                style={{
                left: `calc(${(value / max) * 100}% - ${(value / max) * 20}px + 13px)`
                }}
              >
                {value}
              </div>
              <input
                type="range"
                min="0"
                max={max}
                value={value}
                onChange={handleChange}
                className="slider"
              />
            </div>
            <div className="slider-labels">
              <span>0</span>
              <span>{max}</span>
            </div>
          </div>
        )}
        {value > 0 && max > 0 && (
          <div className='start-btn' onClick={handleGo}>C'est parti !</div>
        )}
        {isNotSub && (
          <NotSub methode={'full'}/>
        )}
   </div>
    )
}

export default Menue;