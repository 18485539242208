import { useContext, useEffect, useRef, useState } from 'react';
import './flash-card.styles.scss'
import { MathContext } from '../../3-context/7-math.context';
const FlashCard = ({cardInfo, position, onAnimationEnd, isNext }) => {
  const cardVersoRef = useRef(null); //
  const {face, setFace} = useContext(MathContext)

    useEffect(() => {
        const renderMath = async () => {
            if (window.MathJax && window.MathJax.typesetPromise) {
              try {
                await window.MathJax.typesetPromise();
              } catch (error) {
                console.error("Erreur pendant le rendu de MathJax :", error);
              }
            }
          };
          renderMath();

        const handleKeyDown = (event) => {
            switch (event.code) {
              case "Space":
                setFace('verso')
              break;
              default:
                console.log(`Touche non gérée : ${event.code}`);
            }
          };
      
          window.addEventListener("keydown", handleKeyDown);
      
          // Nettoyage à la fin du cycle de vie du composant
          return () => {
            window.removeEventListener("keydown", handleKeyDown);
          };
    }, [face, cardInfo]) 
      // Fonction pour mettre à jour la hauteur de la carte
  
    const scrollToCard = () => {
      
      if (cardVersoRef.current) {
        cardVersoRef.current.scrollIntoView({
          behavior: 'smooth', // Défilement fluide
          block: 'center',    // Place la carte au centre de la vue
        });
      }
    };
 
  useEffect(() => {
    if (face === 'verso') {
      scrollToCard();
    }
  }, [face, cardInfo]);
  // Ajouter un écouteur pour les redimensionnements
    return (
        <div className={`card-container ${position} ${isNext && 'next'}`} onAnimationEnd={onAnimationEnd}>
        <div  className={`card ${face === 'verso' && 'move'}`} onClick={() => (face === 'recto' ? setFace('verso') : setFace('recto'))}>
                <div className='title-container'>
                    <h1 className="card-info">
                        {cardInfo['filiere']} - {cardInfo['classe']}A - {cardInfo['theme'] === 'Analyse' ? <span>&#x1F50E;</span> : cardInfo['theme'] === 'Probas' ? <span>&#x1F3B2;</span> : <span>&#x1F4D0;</span>}<span className='theme'>{cardInfo['theme']}</span> : {cardInfo['chapitre']}
                    </h1>
                    <div className='annee'>HEC {cardInfo['annee']}</div>
                </div>
              
                <div className="content">
                    {cardInfo.verso && (
                    <div dangerouslySetInnerHTML={{ __html: cardInfo['recto'] }} />
                    )}
                </div>
        </div>
        
        <div ref={cardVersoRef} className={`card ${face === 'verso' ? 'verso' : 'verso-cacher'}`} onClick={() => (face === 'recto' ? setFace('verso') : setFace('recto'))}>
            <div className='title-container'>
                <h1 className="card-info">&#x1F973; Réponse</h1>
            </div>
            
            <div className="content">

                {cardInfo.verso && (
                    
                <div dangerouslySetInnerHTML={{ __html: cardInfo['verso'] }}/>
                )}
            </div>
        </div>
        {face === 'recto' && <div className='info'>Cliquer sur la carte ou appuyer sur la touche espace du clavier pour afficher le verso de la carte</div>}
    </div>

    )
}

export default FlashCard